<template>
  <div id="page_not_found">
    <section class="bg-cl-secondary py35 px20">
      <div class="container">
        <h2>
          {{ $t("We can't find the page") }}
        </h2>
      </div>
    </section>
    <section class="bg-cl-primary py35 px20">
      <div class="container">
        <div class="lh16 h5 weight-400">
          <p>
            {{ $t("Unfortunately we can't find the page you are looking for.") }}
          </p>
          <p>
            {{ $t('If you need an assistance you can drop us a line on') }}
            <router-link :to="localizedRoute({ path: '/static/kontakty' })" class="cl-secondary no-underline">
              {{ $t('a contact page') }}
            </router-link>
          </p>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6 px40 py40">
            <router-link :to="localizedRoute({ path: '/' })">
              <button-outline
                color="dark"
              >
                {{ $t('Return to Homepage') }}
              </button-outline>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/i18n'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

export default {
  name: 'PageNotFound',
  computed: {
    ...mapGetters({
      ourBestsellersCollection: 'homepage/getBestsellers'
    })
  },
  async asyncData ({ store, route, context }) {
    Logger.log('Entering asyncData for PageNotFound ' + new Date())()
    if (context) {
      context.output.cacheTags.add(`page-not-found`)
      context.server.response.statusCode = 404
    }

    await store.dispatch('homepage/loadBestsellers')
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('404 Page Not Found'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : []
    }
  },
  components: {
    ButtonOutline
  },
  methods: {
    toggleSearchpanel () {
      this.$store.commit('ui/setSearchpanel', true)
    }
  }
}
</script>

<style scoped>
  a {
    text-decoration: underline;
  }
</style>
