<template>
  <div>
    <div class="bg-cl-secondary py35 pl20">
      <div class="container">
        <h1>
          {{ $t('Reset your password') }}
        </h1>
      </div>
    </div>

    <div class="container pt45 pb70">
      <div class="row px20 pt0">
        <div class="col-md-9">
          <div class="mb35">
            <div class="row mb15">
              <div class="col-xs-12 col-sm-6">
                <h3 class="m0 mb5">
                  {{ $t('Create new password') }}
                </h3>
              </div>
            </div>
            <div class="row">
              <form @submit.prevent="resetPass" novalidate>
                <base-input
                  class="mb10"
                  type="password"
                  name="password 1"
                  focus
                  v-model="password"
                  @blur="$v.password.$touch()"
                  :placeholder="$t('New password')"
                  :validations="[
                    {
                      condition: !$v.password.required && $v.password.$error,
                      text: $t('Field is required.')
                    },
                    {
                      condition: !$v.password.minLength && $v.password.$error,
                      text: $t('minimum 4 letters.')
                    }
                  ]"
                />
                <base-input
                  class="mb10"
                  type="password"
                  name="password 2"
                  v-model="passwordRepeat"
                  @blur="$v.passwordRepeat.$touch()"
                  :placeholder="$t('Confirm new password')"
                  :validations="[
                    {
                      condition: !$v.passwordRepeat.sameAs && $v.passwordRepeat.$error,
                      text: $t('passwords must repeat.')
                    },

                  ]"
                />

                <button-full class="mb20" type="submit" data-testid="loginSubmit">
                  {{ $t('reset your password') }}
                </button-full>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import i18n from '@vue-storefront/i18n'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import Login from '@vue-storefront/core/compatibility/components/blocks/Auth/Login'
import { required, minLength, sameAs, email } from 'vuelidate/lib/validators'

export default {
  name: 'PassReset',
  mixins: [Login],
  validations: {
    password: {
      required,
      minLength: minLength(4)
    },
    passwordRepeat: {
      sameAsPassword: sameAs('password')
    }
  },
  data () {
    return {
      remember: false
    }
  },
  computed: {

  },
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('Reset your Password'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : []
    }
  },
  components: {
    BaseInput,
    ButtonFull
  },
  watch: {
    password (val) {
      this.password = val.replace(/\W/g, '');
    },
    passwordRepeat (val) {
      this.passwordRepeat = val.replace(/\W/g, '');
    }
  },
  methods: {
    resetPass () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Please fix the validation errors'),
          action1: { label: this.$t('OK') }
        })
        return
      }
      this.callConfirmResetPassword(this.password, this.$route.query.token)
    },
    nameKeydown (e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    toggleSearchpanel () {
      this.$store.commit('ui/setSearchpanel', true)
    }
  }
}
</script>

<style scoped>
  a {
    text-decoration: underline;
  }
</style>
