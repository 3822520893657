var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-cl-secondary py35 pl20"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v("\n        "+_vm._s(_vm.$t('Reset your password'))+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"container pt45 pb70"},[_c('div',{staticClass:"row px20 pt0"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"mb35"},[_c('div',{staticClass:"row mb15"},[_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('h3',{staticClass:"m0 mb5"},[_vm._v("\n                "+_vm._s(_vm.$t('Create new password'))+"\n              ")])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.resetPass($event)}}},[_c('base-input',{staticClass:"mb10",attrs:{"type":"password","name":"password 1","focus":"","placeholder":_vm.$t('New password'),"validations":[
                  {
                    condition: !_vm.$v.password.required && _vm.$v.password.$error,
                    text: _vm.$t('Field is required.')
                  },
                  {
                    condition: !_vm.$v.password.minLength && _vm.$v.password.$error,
                    text: _vm.$t('minimum 4 letters.')
                  }
                ]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('base-input',{staticClass:"mb10",attrs:{"type":"password","name":"password 2","placeholder":_vm.$t('Confirm new password'),"validations":[
                  {
                    condition: !_vm.$v.passwordRepeat.sameAs && _vm.$v.passwordRepeat.$error,
                    text: _vm.$t('passwords must repeat.')
                  } ]},on:{"blur":function($event){return _vm.$v.passwordRepeat.$touch()}},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),_vm._v(" "),_c('button-full',{staticClass:"mb20",attrs:{"type":"submit","data-testid":"loginSubmit"}},[_vm._v("\n                "+_vm._s(_vm.$t('reset your password'))+"\n              ")])],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }